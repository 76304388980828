﻿export function submitForm(formIdentifier: string, delay?: number) {
	var form = document.getElementById(formIdentifier) as HTMLFormElement;

	const submitAction = () => {
		if (form != null) {
			form.submit();
		}
	};

	if (delay !== undefined) {
		setTimeout(submitAction, delay);
	} else {
		submitAction();
	}
}

export function focusElement(element: HTMLElement) {
	if (element != null) {
		element.focus();
	}
}